/*import React, { useRef, useState } from "react";
//import emailjs from "emailjs-com";
//import { Form, Button, FloatingLabel, Col, Modal } from "react-bootstrap";
import {} from "../styles/ContactUsStyles.css";

const ContactUsScreen = () => {

  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [agreedPolicy, setPolicy] = useState(false); // Estado para el checkbox
  const [agreed, setAgreed] = useState(false); // Estado para el checkbox

  //const [validated, setValidated] = useState(false);
  let [clickInfo, setClickInfo] = useState(false); // Estado para el checkbox

  const form = useRef();

  const [formData] = useState({
    name: '',
    Company: '',
    Email: '',
    Message: '',
    Policy: '',
    Agree: '',
  });

  const isEmailValid = (value) => {
    // Expresión regular para validar el formato de un correo electrónico
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    formData.name = name
    formData.Company = company
    formData.Email = email
    formData.Message = message
    formData.Policy = agreedPolicy
    formData.Agree = agreed
    console.log(formData)

      
    if(formData.Policy === false || isEmailValid(formData.Email) === false){
      setClickInfo(true)
    }else{
      setClickInfo(false)
      
    try {
      const response = await fetch('https://fund360-email.vercel.app/send-form-contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

      if (response.ok) {
      } else {
        console.error('Error al enviar el formulario');
      }
    } catch (error) {
      console.error('Error de red', error);
    }
    }


   -- let serviceId = process.env.REACT_APP_SERVICE_ID;
    let templateId = process.env.REACT_APP_TEMPLATE_ID;
    let publicKey = process.env.REACT_APP_PUBLIC_KEY;
    emailjs.sendForm(serviceId, templateId, "#contact-form", publicKey).then(
      () => {
        setValidated(false);
      },
      (error) => {
        alert("There was a problem sending your message.", error);
      }
    );--

  };


  return (
    <>
      <div className="firstContactUsDiv">
        <div className="firstSubDivMu">
          <div className="logoMUDiv">
            <img src="/logo-header360.svg" alt="360-logo" className="logoMU" />
          </div>

          <div className="secondSubDivMu">
            <div className="ukImageDiv">
              <img src="/images/uk-map.svg" alt="ukMap" className="ukMap" />
              <p className="pAddressTitle">UNITED KINGDOM</p>
              <p className="pAddress">The Hever Business Centre,</p>
              <p className="pAddress">The Old Station,</p>
              <p className="pAddress">Hever,</p>
              <p className="pAddress">TN8 7ER,</p>
              <p className="pAddress">+44(0)795 714 7852</p>
            </div>

            <div className="spImageDiv">
              <img
                src="/images/spain-map.png"
                alt="Map-Spain"
                className="spainMap"
              />
              <p className="pAddressTitleSP">ESPAÑA</p>
              <p className="pAddress">Calle Félix Boix, 3</p>
              <p className="pAddress">PLanta 1º</p>
              <p className="pAddress">MADRID - 28035</p>
              <p className="pAddress">+35 915 797 084</p>
            </div>
          </div>
        </div>

        <div className="formDivMu">
          <h1 className="formTitle">Contact Us</h1>
       

          <form className="formEmail" ref={form}>

<div className="inputSpace">
  <input 
  placeholder="Name" 
  className="inputStyle"
  value={name}
  name="name"
  onChange={(e) => setName(e.target.value)}>
  </input>
</div>

<div className="inputSpace2">
  <input 
  type="email"
  placeholder="Email" 
  className="inputStyle"
  value={email}
  name="reply_to"
  onChange={(e) => setName(e.target.value)}>            
      </input>
</div>

<div className="inputSpace">
  <input 
  placeholder="Company" 
  className="inputStyle"
  value={company}
  name="company"

  onChange={(e) => setCompany(e.target.value)}>
  </input>
</div>

<div className="inputSpace">
  <input 
  type="email"
  placeholder="Email" 
  value={email}
  name="email"
  className={(!email && clickInfo) ? 'inputRed' : 'inputStyle'}
  onChange={(e) => setEmail(e.target.value)}>
  </input>
</div>



<div className="inputSpace">
  <textarea 
  placeholder="Message (Optional)" 
  className="textAreaSpace"
  value={message}
  name="message"
  onChange={(e) => setMessage(e.target.value)}
  > 
  </textarea>

  <div className="inputCheckBox">
    <span   className={(!agreedPolicy && clickInfo) ? 'checkbox-rojo' : ''}> Accept Privacy Policy and Conditions </span>
    <input type="checkBox"       
    checked={agreedPolicy}
    name="agreedPolicy"
    onChange={() => setPolicy(!agreedPolicy)} required/>
  </div>

  <div className="inputCheckBox">
    <span className="checkText"> Subscribe to relevant updates and conditions </span>
    <input type="checkBox"       
    checked={agreed}
    name="agreed"
    onChange={() => setAgreed(!agreed)}/>
  </div>
</div>

<button type="submit"  className={`btnSubmit ${agreedPolicy && isEmailValid(email) ? '' : 'btnSubmit-rojo'}`}  onClick={sendEmail}   >Submit</button>

</form>
        </div>
      </div>
    </>
  );
};
export default ContactUsScreen;
*/
import React, { useRef, useState } from "react";
//import emailjs from "emailjs-com";
import { Form, Button, FloatingLabel, Col, Modal } from "react-bootstrap";
import {} from "../styles/ContactUsStyles.css";

const ContactUsScreen = () => {
  const [validated, setValidated] = useState(false);
  const form = useRef();

  const [formData] = useState({
    name: '',
    Company: '',
    Email: '',
    Message: '',
    Policy: '',
    Agree: '',
  });

  const sendEmail = (e) => {
    console.log(form)
    e.preventDefault();

    formData.name = form.current[0].value
    formData.Company = form.current[1].value
    formData.Email = form.current[2].value
    formData.Message = form.current[3].value
    formData.Policy = form.current[4].value
    formData.Agree = form.current[5].value

    try {
      const response =  fetch('https://fund360-email.vercel.app/send-form-contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

      if (response.ok) {
        setValidated(false);

      } else {
        console.error('Error al enviar el formulario');
      }
    } catch (error) {
      console.error('Error de red', error);
    }

    /*let serviceId = process.env.REACT_APP_SERVICE_ID;
    let templateId = process.env.REACT_APP_TEMPLATE_ID;
    let publicKey = process.env.REACT_APP_PUBLIC_KEY;

   emailjs.sendForm(serviceId, templateId, "#contact-form", publicKey).then(
      () => {
        setValidated(false);
      },
      (error) => {
        alert("There was a problem sending your message.", error);
      }
    );*/

    
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleSubmit = (event) => {
    setValidated(true);
    console.log(event)
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      sendEmail(event);
      setValidated(false);
      event.currentTarget.reset();
      setShow(true);
    }
  };

  return (
    <>
      <div className="firstContactUsDiv">
        <div className="firstSubDivMu">
          <div className="logoMUDiv">
            <img src="/logo-header360.svg" alt="360-logo" className="logoMU" />
          </div>

          <div className="secondSubDivMu">
            <div className="ukImageDiv">
              <img src="/images/uk-map.svg" alt="ukMap" className="ukMap" />
              <p className="pAddressTitle">UNITED KINGDOM</p>
              <p className="pAddress">The Hever Business Centre,</p>
              <p className="pAddress">The Old Station,</p>
              <p className="pAddress">Hever,</p>
              <p className="pAddress">TN8 7ER,</p>
              <p className="pAddress">+44(0)795 714 7852</p>
            </div>

            <div className="spImageDiv">
              <img
                src="/images/spain-map.png"
                alt="Map-Spain"
                className="spainMap"
              />
              <p className="pAddressTitleSP">ESPAÑA</p>
              <p className="pAddress">Calle Félix Boix, 3</p>
              <p className="pAddress">PLanta 1º</p>
              <p className="pAddress">MADRID - 28035</p>
              <p className="pAddress">+35 915 797 084</p>
            </div>
          </div>
        </div>

        <div className="formDivMu">
          <h1 className="formTitle">Contact Us</h1>
          <Form
            noValidate
            ref={form}
            onSubmit={handleSubmit}
            validated={validated}
            id="contact-form"
          >
            <Form.Group className="mb-3" controlId="contactUsForm">
              <FloatingLabel
                controlId="floatingTextarea"
                label="Name"
                className="mb-3 border-dark"
              >
                <Form.Control
                  type="text"
                  placeholder="Name"
                  name="user_name"
                  required
                  className="border-dark"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your name.
                </Form.Control.Feedback>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingTextarea"
                label="Company"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Company"
                  name="user_company"
                  required
                  className="border-dark"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid company name.
                </Form.Control.Feedback>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingTextarea"
                label="E-mail"
                className="mb-3"
              >
                <Form.Control
                  type="email"
                  placeholder="Email"
                  name="user_email"
                  required
                  className="border-dark"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email.
                </Form.Control.Feedback>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingTextarea"
                label="Message (optional)"
                className="mb-3"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Leave a comment here"
                  name="user_message"
                  style={{ height: "90px" }}
                  className="border-dark"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a message.
                </Form.Control.Feedback>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </FloatingLabel>
              <Col md={{ span: 10, offset: 2 }}>
                <Form.Check
                  label="Accept Privacy Policy and Conditions"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a message.
                </Form.Control.Feedback>
              </Col>
              <Col md={{ span: 10, offset: 2 }}>
                <Form.Check label="Subscribe to relevant updates and news" />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Col>
              <Col md={{ span: 10, offset: 5 }} className="btmFormDiv">
                <Button
                  type="submit"
                  value="send"
                  className="mb-2 mt-3 modalButton"
                  size="lg"
                >
                  Submit
                </Button>
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header
                    closeButton
                    className="modalTextCentered"
                  ></Modal.Header>
                  <Modal.Body className="modalTextCentered">
                    Your message has been succesfully sent.
                  </Modal.Body>
                  <Modal.Footer className="modalTextCentered">
                    <Button
                      className="modalButton rounded-pill"
                      onClick={handleClose}
                    >
                      Accept
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Col>
            </Form.Group>
          </Form>
        </div>
      </div>
    </>
  );
};
export default ContactUsScreen;