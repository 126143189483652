import React, { useState, useEffect ,useRef  } from "react";
import emailjs from '@emailjs/browser';

import iconBlue from "../../styles/icons/nextArrow.png"
//import imgAssessmen from "../../styles/images/assessmentImgInfo.jpeg"
//import imgAssessmen2 from "../../styles/images/assessmentImgInfo2.jpeg"
import imgAssessment from "../../styles/images/assessmentImg.png"
import imgAssessmentAj from "../../styles/images/logoAJ.png"
import "../../styles/assessment.css";
const AssessmenOfValue =  () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [mailSend, setMailSend] = useState(false);


  const textAssessmentNew = `[London, 4th January 2024] – AJ Bell, a leading UK investment platform is using data from 360 Fund Insight to augment its processes in assessing the value provided by the funds it distributes. AJ Bell will utilise 360 Fund Insight’s breadth of coverage of offshore funds to support its wide fund universe and provide valuable choice to financial advisers and customers.​`


  const textAssessment = `The FCA requires Platforms to show a fund's AoV with responsibility for the content on their platforms. However, Manufacturers do not always provide one. This is unhelpful as the result only adds an operational burden to the operations teams.  

  Funds with a poor AoV are less likely to attract investors in all Target Markets. Increasing fund coverage impacts operational costs and an unfunded expense when poor value funds fail to attract expected assets. Platforms and Distributors are considering the suitability of funds that have poor assessments, this is reinforced by the FCA who suggest that they are not offered to investors. In some cases, Distributors are meeting their commitment to regulation by informing investors in poor value funds, or no longer including funds with poor assessments. This is putting pressure on Manufacturers and creating a conflict of interest.  
  
  Distributors can use manufacturers' AoV information. Concerns arise however, on the different methodologies employed and the independence of results from the manufacturer or their Authorised Corporate Director's (ACD).  
  
   
  
  With assessments of value overtime across a fund range, a Manufacturer can focus on marketing the funds likely to gather assets and avoid the expense of maintaining those with unfavourable assessments of value.  
  
   
  
  Current regulations lead to AoV reporting inconsistencies between onshore and offshore share classes, posing challenges for Distributors and Manufacturers alike.`

  const textSendEmail = ` Thank you for requesting the assessment of value. Your request has been accepted and we will send a report to you shortly.

  We would be delighted to discuss your requirements in person.
  
  If you would like to speak to a member of our team, please:

  `

  
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [agreedPolicy, setPolicy] = useState(false); // Estado para el checkbox
  const [agreed, setAgreed] = useState(false); // Estado para el checkbox
  let [typeInfo, setTypeInfo] = useState("");

  let [clickInfo, setClickInfo] = useState(false); // Estado para el checkbox








  const closeDialog = () => {
    setIsDialogOpen(false);
    setMailSend(false)
    setName("");
    setCompany("");
    setEmail("");
    setRole("");
    setPhone("");
    setMessage("");
    setAgreed(false);
    setPolicy(false);
    setTypeInfo("")
    setClickInfo(false)
  };

    const [formData] = useState({
      name: '',
      Company: '',
      Email: '',
      reply_to: '',
      Role: '',
      Phone: '',
      Message: '',
      Policy: '',
      Agree: '',
    });
  
  const handleFormSubmit =  async (e) => {
   // e.preventDefault();
    formData.typeInfo = typeInfo
    formData.name = name
    formData.Company = company
    formData.Email = email
    formData.Role = role
    formData.Phone = phone
    formData.Message = message
    formData.Policy = agreedPolicy
    formData.Agree = agreed

    if(formData.Policy === false || isEmailValid(formData.Email) === false){
        console.log("Error")
    }else{

    

    try {
      const response = await fetch('https://fund360-email.vercel.app/enviar-formulario', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

      if (response.ok) {
      } else {
        console.error('Error al enviar el formulario');
      }
    } catch (error) {
      console.error('Error de red', error);
    }
    
    }
  }
  

    const openDialog = async (a) => {
      setTypeInfo(a)

      setIsDialogOpen(true);
  
    };

  useEffect(() => {

    if (isDialogOpen) {
      document.body.classList.add("bodyNoOverflow");
    } else {
      document.body.classList.remove("bodyNoOverflow");
    }

    return () => {
      document.body.classList.remove("bodyNoOverflow");
    };
  }, [isDialogOpen]);

  const isEmailValid = (value) => {
    // Expresión regular para validar el formato de un correo electrónico
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };


  const handlePhoneNumberChange = (e) => {
    // Eliminar caracteres que no sean números
    const cleanedPhoneNumber = e.target.value.replace(/\D/g, "");
    setPhone(cleanedPhoneNumber);
  };
  const form = useRef();


  const sendEmail =  (e) => {
    e.preventDefault();
    handleFormSubmit()
  
    if(formData.Policy === false || isEmailValid(formData.Email) === false){
      setClickInfo(true)
    }else{
      setClickInfo(false)

    if(typeInfo === "Asset Managers"){
     emailjs.sendForm('service_97077vl', 'template_pmc3x7t', form.current, 'FOYviqc9XDmIskpTF')
      .then((result) => {
        setIsDialogOpen(false);
        setMailSend(true)
        }, 
      (error) => {
          console.log(error.text);
      });
    }else{
      emailjs.sendForm('service_97077vl', 'template_vr7wpsz', form.current, 'FOYviqc9XDmIskpTF')
      .then((result) => {
        setIsDialogOpen(false);
        setMailSend(true)
      }, 
      (error) => {
          console.log(error.text);
      });
    }
  }


  };

  return (
    <>
         {isDialogOpen && (
        <div className="dialogOverlay">

          <div className="dialogBox">

            <div className="contentX"><button className="buttonCloseDialog" onClick={closeDialog}>X</button></div>
            <h2 className="contactUsTitle">DOWNLOAD REPORT</h2>
            <p className="typeInfo">{typeInfo}</p>
            <form className="formEmail" ref={form}>

              <div className="inputSpace">
                <input 
                placeholder="Name" 
                className="inputStyle"
                value={name}
                name="name"
                onChange={(e) => setName(e.target.value)}>
                </input>
              </div>

              <div className="inputSpace2">
                <input 
                type="email"
                placeholder="Email" 
                className="inputStyle"
                value={email}
                name="reply_to"
                onChange={(e) => setName(e.target.value)}>            
                    </input>
              </div>

              <div className="inputSpace">
                <input 
                placeholder="Company" 
                className="inputStyle"
                value={company}
                name="company"

                onChange={(e) => setCompany(e.target.value)}>
                </input>
              </div>

              <div className="inputSpace">
                <input 
                type="email"
                placeholder="Email" 
                value={email}
                name="email"
                className={(!email && clickInfo) ? 'inputRed' : 'inputStyle'}
                onChange={(e) => setEmail(e.target.value)}>
                </input>
              </div>
           

              <div className="inputSpace">
                <input
                placeholder="Role"
                className="inputStyle"
                value={role}
                name="role"
                onChange={(e) => setRole(e.target.value)}>                
                </input>
              </div>
           

              <div className="inputSpace">
                <input 
                type="tel"
                placeholder="Phone (Optional)" 
                className="inputStyle"
                value={phone}
                name="phone"
                onChange={handlePhoneNumberChange}>
                </input>
              </div>
           

              <div className="inputSpace">
                <textarea 
                placeholder="Message (Optional)" 
                className="textAreaSpace"
                value={message}
                name="message"
                onChange={(e) => setMessage(e.target.value)}
                > 
                </textarea>

                <div className="inputCheckBox">
                  <span   className={(!agreedPolicy && clickInfo) ? 'checkbox-rojo' : ''}> Accept Privacy Policy and Conditions </span>
                  <input type="checkBox"       
                  checked={agreedPolicy}
                  name="agreedPolicy"
                  onChange={() => setPolicy(!agreedPolicy)} required/>
                </div>

                <div className="inputCheckBox">
                  <span className="checkText"> Subscribe to relevant updates and conditions </span>
                  <input type="checkBox"       
                  checked={agreed}
                  name="agreed"
                  onChange={() => setAgreed(!agreed)}/>
                </div>
              </div>

            <button type="submit"  className={`btnSubmit ${agreedPolicy && isEmailValid(email) ? '' : 'btnSubmit-rojo'}`}  onClick={sendEmail}   >Submit</button>

            </form>


          </div>
        </div>
      )}

      { mailSend && (
        <div className="dialogOverlay">

        <div className="dialogBoxSuccess">

          <div className="contentX"><button className="buttonCloseDialog" onClick={closeDialog}>X</button></div>
          <h2 className="contactUsTitle">Message delivered</h2>
          <div className="formEmail">
          <span  className="textEmailSuccess">
            {textSendEmail}
            <div >
            Email: 
            <a className="linkContact" href="mailto:manuel.hernandez@360fundinsight.com">manuel.hernandez@360fundinsight.com</a>

            </div>
            <div>
            or Call: <a className="linkContact" href="tel:34609469354">+34 (0)609469354</a>

            </div>
          </span>
          </div>
          </div>
        </div>  
          
        )}
    <div className="boxParts">

      <div className="partText">

      <h1 className="titleText">
            360 Fund Insight strikes agreement to provide data to AJ Bell in support of consumer
            duty assessments of value for offshore funds
      </h1>

        <div className="ajBox"> 
        <img  src={imgAssessmentAj} alt="Assessment of value img" className="ajImg"/>
        </div>
    <span className="textPrincipalNew">{textAssessmentNew}</span>
    <span className="textPrincipal">{textAssessment}</span>

      </div>

      <div className="partOptions">

        <div className="managerBox">

          <div className="titleManager" onClick={() => openDialog("Asset Managers")}>
            <span className="textManagerTitle">
            Asset Managers download a report here
            </span>

            <div className="contentArrow">
            <img className="iconArrow" alt="iconArrow" src={iconBlue} />

            </div>

          </div>

          <div className="boxValues">

            <div className="valueFund">
              <span className="circleColorDarkGreen"></span>
              <p  className="textValue">Fund offers a <span className="textBold">VERY BEST VALUE</span> for investors</p>
            </div>

            <div className="valueFund">
              <span className="circleColorGreen"></span>
              <p  className="textValue">Fund offers a <span className="textBold">FAIR VALUE</span> for investors</p>
            </div>

            <div className="valueFund">
              <span className="circleColorOrange"></span>
              <p className="textValue">Fund offers a <span className="textBold">POOR VALUE</span> for investors</p>
            </div>

            <div className="valueFund">
              <span className="circleColorRed"></span>
              <p  className="textValue">Fund <span className="textBold">DOES NOT offer Value</span> for investors</p>
            </div>

        </div>

      </div>

        <div className="platformBox">

        <div className="titleManager"  onClick={() => openDialog("Platforms & Fund Distributors")}>
            <span className="textManagerTitle">
            Platforms & Fund Distributors download a report here
            </span>

            <div className="contentArrow">
            <img className="iconArrow" alt="iconArrow" src={iconBlue}/>

            </div>
          </div>

          <div  >

            <img src={imgAssessment} alt="Assessment of value img" className="boxValuesAssessment"/>

          </div>

        </div>

      </div>

    </div>
    </>
  );
};

export default AssessmenOfValue;
