import React from "react";
import {
  Image,
}from "../styles/FooterStyles";

 import "../styles/footerStyle.css";

import { Link } from "react-router-dom";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'


const Footer = () => {
  return (
    <div >
      {/* <h1 style={{ color: "white",
				textAlign: "center",
				marginTop: "-50px" }}>
		360
	</h1> */}
  <div className="box">
          <div className="boxSocialMedia"> 
            <a target = "_blank" rel="noopener noreferrer" href="https://twitter.com/360FundInsight" className="footerLinkSocial">
              <FontAwesomeIcon icon = {faTwitter}>
                <span>X</span>
              </FontAwesomeIcon>
            </a>

            <a target = "_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/360-fund-insight-limited/about/" className="footerLinkSocial">
              <FontAwesomeIcon icon = {faLinkedinIn}>
                <span>LinkedIn</span>
              </FontAwesomeIcon>
            </a>
            
          </div>
        <div className="boxChildren">
          <div className="companyNameBox"> 
            <Image
              src={"/logo-footer360.svg"}
              alt="360 Fund Insight Limited "
              />
            <span className="spanCompany">
              {" "}
              This website is for investment professionals only. It is not
              intended for private investors. Private investors who are
              interested in our investment services should contact a financial
              professional.
            </span>
            
          </div>
          <div className="boxDirections">
          <div className="directionsTitles">
            <h2 className="titleC">COMPANY</h2>
            <Link to="/" className="footerLink">
              {" "}
              Home{" "}
            </Link>
            <Link to="/meetus" className="footerLink">
              About us
            </Link>
            <Link to="/contactus" className="footerLink">
              Contact us
            </Link>
          </div>
          {/* <Column>
            <Heading>OUR SERVICES</Heading>
            <Link to="/research" className="footerLink">
              Research
            </Link>
            <Link to="/governance" className="footerLink">
              Governance
            </Link>
            <Link to="/solutions" className="footerLink">
              Solutions
            </Link>
          </Column> */}
          {/* <Column>
            <Heading>LEGAL</Heading>
            <Link to="/cookies" className="footerLink">
              Cookies policy
            </Link>
            <Link to="/legalinfo" className="footerLink">
              Legal information
            </Link>
            <Link to="/privacy" className="footerLink">
              Privacy policy
            </Link>
          </Column> */}
     
          </div>
        </div>
        </div>
    </div>
  );
};
export default Footer;
